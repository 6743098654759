.loading-container {
  width: 100%;
  height: 100vh;
  background-color: #666666; }

.mainLoader {
  margin: auto;
  padding-top: 20vh; }

.p15 {
  padding: 15px; }

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

a {
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s; }

.about .title {
  background: #6ac8c8; }

.skills .title {
  background: #92c14d; }

.resume .title {
  background: #f97c39; }

.contact .title {
  background: #ffbe00; }

.content {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  position: relative;
  width: 960px; }

.divider {
  margin: 30px auto 0;
  position: relative;
  text-align: center;
  width: 600px; }

.divider span:before {
  border-bottom: 1px dashed #cfcfcf;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 0; }

.work-item {
  border-left: 1px solid #efefef;
  padding-left: 30px;
  position: relative; }

.work-ico {
  background: #2d2d2d;
  border: 5px solid #ffffff;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  background-clip: padding-box;
  color: #ffffff;
  display: inline-block;
  height: 36px;
  left: -4%;
  line-height: 28px;
  margin-top: -22px;
  position: absolute;
  text-align: center;
  top: 80px;
  width: 36px; }

.work-desc h6 {
  color: #666666;
  font-size: 11px;
  margin-bottom: 5px; }

.work-desc p {
  color: #999999;
  font-size: 13px; }

.form-btn {
  background: #2d2d2d;
  color: #ffffff;
  display: block;
  font-size: 14px;
  height: 45px;
  text-transform: uppercase;
  width: 80px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  float: right; }

#toTop {
  display: none;
  text-decoration: none;
  position: fixed;
  bottom: 0.75rem;
  right: 0.75rem;
  overflow: hidden;
  width: 43px;
  height: 43px;
  border: none;
  z-index: 100; }

#toTopHover {
  display: block;
  overflow: hidden;
  float: left; }

.from-content {
  position: relative; }

.spinner-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.6);
  display: none; }

.spinner {
  margin: 200px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px; }

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out; }

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.ico > a {
  color: #ffffff;
  text-decoration: none; }

p > a {
  color: #000;
  text-decoration: none; }

@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

/*
 * MEDIA QUERIES
 */
/* Responsive: Portrait tablets and up */
@media only screen and (max-width: 959px) and (min-width: 768px) {
  .quotes {
    width: 70%; }
  .content {
    width: 700px; }
  #home div canvas {
    display: none; } }

@media only screen and (max-width: 767px) and (min-width: 480px) {
  .quotes {
    width: 80%; }
  .quotes {
    width: 80%; }
  .content {
    width: 400px; }
  .divider {
    width: 375px; }
  .img-profile:before,
  .img-profile:after {
    display: none; }
  .profile {
    margin-bottom: 180px;
    margin-top: 100px; }
  .dl-btn {
    left: 33%;
    top: 107.5%; }
  .fol-btn {
    right: 33%;
    top: -50.5%; }
  .info li .ico {
    top: -70px; }
  .info ul {
    margin-top: 50px;
    padding: 0; }
  .info li {
    height: 100px; }
  .info li:last-child {
    height: 50px; }
  #home div canvas {
    display: none; } }

@media only screen and (max-width: 479px) {
  header {
    display: none; }
  .quotes {
    width: 95%; }
  .content {
    width: 100%; }
  .divider {
    width: 100%; }
  .img-profile:before,
  .img-profile:after {
    display: none; }
  .profile {
    margin-bottom: 140px;
    margin-top: 100px; }
  .dl-btn {
    left: 33%;
    top: 107.5%; }
  .fol-btn {
    right: 33%;
    top: -50.5%; }
  .info ul {
    margin-top: 50px;
    padding: 50px; }
  .info li {
    height: 100px;
    width: 185px; }
  .info li .ico {
    top: -70px; }
  .info li:last-child {
    height: 50px; }
  #home div canvas {
    display: none; } }

.row {
  margin-left: 0 !important;
  margin-right: 0 !important; }
