/* Custom page footer */
.navbar,
.footer {
  margin-bottom: 0;
  background: #2d2d2d; }

.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5; }

.quotes {
  text-align: center;
  color: #fff;
  padding: 20px;
  width: 60%;
  margin-left: auto;
  margin-right: auto; }

.quote-body {
  font-size: 2.5em;
  margin-bottom: 0; }

.quote-by {
  text-align: right; }

/* =Blockquote
-------------------------------------------------------------- */
blockquote {
  position: relative;
  padding: 30px 60px;
  text-align: center;
  font-size: 17px; }

blockquote:before,
blockquote:after {
  position: absolute;
  width: 60px;
  height: 60px;
  font-size: 120px;
  line-height: 1; }

blockquote:before {
  top: 0;
  left: 0;
  content: "\201C"; }

blockquote:after {
  top: 0;
  right: 0;
  content: "\201D"; }
